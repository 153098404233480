<template>
  <div class="dashboard">
    <table class="dtable">
      <tr>
        <td style="width:50%;">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="Item" />
            <el-table-column prop="value" label="Value" width="180" />
          </el-table>
          <div style="margin-top: 20px">
            <el-button @click="loadSystemStats(true, true)">Refresh</el-button>
          </div>
        </td>
        <td style="width:50%;"></td>
      </tr>
    </table>
  </div>
</template>
<script>
import { getData } from "../../service/api";

export default {
  name: "dashboard",
  data() {
    return {
      tableData: [],
    };
  },
  computed: {},
  created() {
    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      this.loadSystemStats(false, true);
    },
    loadSystemStats(refresh, showGlobalLoading) {
      getData(
        `common/getSystemStatistic`,
        { refresh: refresh },
        {
          isShowLoading: showGlobalLoading,
        }
      ).then((res) => {
        if (res.result && res.code === "200") {
          this.tableData = res.result;
        }
      });
    },
  },
};
</script>
<style scoped>
.dashboard .subject {
  height: 38px;
  line-height: 38px;
  color: #3f9dff;
  text-align: center;
  border-bottom: solid 2px #e4e7ed;
  font-weight: 400;
}
.dashboard .dtable {
  display: block;
  min-width: 100%;
  word-wrap: break-word;
  display: table;
}
</style>
